<template>
    <el-dialog :title="title" :visible.sync="fuPingVisible" @open="open" @close="cancel"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" class="x-edit">
            <el-form-item label="副屏设置" prop="fuPingSheZhi" class="line">
                <x-radio dict-type="T_FU_PING_SET" v-model="form.fuPingSheZhi" @change="ruPingChange"></x-radio>
            </el-form-item>
            <el-form-item label="图片" prop="fuPingImg" class="line">
                <x-uploader multiple v-model="form.fuPingImg" :list-type="listType" accept="image/jpeg,image/png"></x-uploader>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import { update, getUpdate} from "@/service/sbgl/ShouYinJi";
    import {deepCopy, deepMerge} from "@/util/objects"
    import XUploader from "@/components/x/upload/XUploader";
    import {DICT_TYPE_FU_PING_SET} from "@/util/constant"

    export default {
        name:"ShouYinJiFuPingEdit",
        mixins: [],
        components:{XUploader},
        beforeCreate() {
            this.listType = "picture"
            this.defaultForm = {
                id: null,
                fuPingSheZhi: DICT_TYPE_FU_PING_SET.YZPYZ.key, // 副屏设置
                moBanLX: "", // 模版类型
                fuPingImg:[]
            };
        },
        data(){
            return {
                fuPingVisible:false,
                setMultiple:true,
                loading: false,
                form: deepCopy(this.defaultForm),
                title:"副屏设置"
            }
        },
        methods:{
            cancel(){
                this.save()
                this.fuPingVisible = false
            },
            open(){
                getUpdate().then((res)=>{
                    this.form = deepMerge(this.defaultForm, res.data);
                })
            },
            ruPingChange(val){
                this.save()
            },
            uploadSuccess(response, file, uploadFiles){
                if(this.form.fuPingImg.length>0){
                    this.form.fuPingImg.shift()
                }
                // console.log("这是需要上传的form数据：",this.form)
            },
            async save(){
                this.loading = true;
                update(this.form).then((res)=>{
                    this.$message.success(res.msg);
                }).finally(()=>{
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
