import { render, staticRenderFns } from "./ShouYinJiLunBoEdit.vue?vue&type=template&id=6ca9eaf9&scoped=true&"
import script from "./ShouYinJiLunBoEdit.vue?vue&type=script&lang=js&"
export * from "./ShouYinJiLunBoEdit.vue?vue&type=script&lang=js&"
import style0 from "./ShouYinJiLunBoEdit.vue?vue&type=style&index=0&id=6ca9eaf9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca9eaf9",
  null
  
)

export default component.exports