<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <div class="x-search-item" style="height: 100px">
                <el-button type="primary" @click="handleFuPing">收银机副屏设置</el-button>
                <el-button type="primary" @click="handleLunBo">自助收银轮播设置</el-button>
            </div>
        </div>
        <fu-ping-edit ref="fuPing"/>
        <lun-bo-edit ref="lunBo"/>
    </div>
</template>
<script>
    import FuPingEdit from "@/view/sbgl/syd/ShouYinJiFuPingEdit";
    import LunBoEdit from "@/view/sbgl/syd/ShouYinJiLunBoEdit";

    export default {
        name: "ShouYinJiList",
        mixins: [],
        components: {FuPingEdit, LunBoEdit},
        data() {
            return {
            }
        },
        methods:{
            handleFuPing(){
                this.$refs.fuPing.fuPingVisible = true
            },
            handleLunBo(){
                this.$refs.lunBo.lunBoVisible = true
            }
        }
    }
</script>

<style scoped>
</style>
