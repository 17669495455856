<template>
    <el-image v-if="!native"
              v-bind="$attrs"
              v-on="$listeners"
              :src="srcOrLoading"
              v-loading="loading">
        <slot name="placeholder" slot="placeholder"/>
        <slot name="error" slot="error"/>
    </el-image>
    <component-img v-else
                   :src="srcOrLoading"
                   v-bind="$attrs"
                   v-on="$listeners">
    </component-img>
</template>

<script>
    import AttachmentPreviewer from '@/components/x/upload/AttachmentPreviewer';
    import loadingSvg from './loading.svg';


    export default {
        name: "XImage",
        mixins: [AttachmentPreviewer],
        components: {
            ComponentImg: {
                functional: true,
                render: (h, ctx) => (<img {...ctx.data}>{ctx.children}</img>)
            }
        },
        props: {
            attachmentId: String,
            native: {   //是否直接使用原生的img元素, 否则使用el-image
                type: Boolean,
                default: false
            },
            attachment: Array
        },
        data() {
            return {
                src: '',
                loading: false
            }
        },
        computed: {
            srcOrLoading() {
                return this.loading ? loadingSvg : this.src;
            }
        },
        watch: {
            attachmentId: {
                handler(val) {
                    if (val) {
                        this.loading = true;
                        this.getBlobUrlOfAttachment(val)
                            .then(url => this.src = url)
                            .finally(_ => this.loading = false);
                    } else {
                        this.src = '';
                    }
                },
                immediate: true
            },
            attachment:{
                handler(arr) {
                    if(this.attachmentId) return;
                    if (arr && arr.length > 0) {
                        this.loading = true;
                        this.getBlobUrlOfAttachment(arr[0].id)
                            .then(url => this.src = url)
                            .finally(() => this.loading = false);
                    } else {
                        this.src = '';
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>
