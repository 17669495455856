import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/shou-yin-ji/list',
        method: 'post',
        data
    })
}

//基础列表
export function getLunBoList(data) {
    return request({
        url: '/shou-yin-ji/get-lun-bo-list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/shou-yin-ji/add',
        method: 'post',
        data
    })
}


//获取编辑页数据
export function getUpdate() {
    return request({
        url: `/shou-yin-ji/get-update`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/shou-yin-ji/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/shou-yin-ji/update',
        method: 'post',
        data
    })
}

//轮播图启用/禁用
export function lunBoUse(data) {
    return request({
        url: '/shou-yin-ji/lun-bo-use',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/shou-yin-ji/delete/${id}`,
        method: 'post',
    })
}

//删除收银轮播背景图
export function deleteLunBo(id) {
    return request({
        url: `/shou-yin-ji/delete-lun-bo/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/shou-yin-ji/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'shou-yin-ji-list',
    component: () => import('@/view/sbgl/syd/ShouYinJiList'),
    name: 'ShouYinJiList',
    meta: {title: '收银机', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"收银机基础列表","methodUrl":"/shou-yin-ji/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"收银机添加保存","methodUrl":"/shou-yin-ji/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"收银机获取编辑数据","methodUrl":"/shou-yin-ji/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"收银机获取详情数据","methodUrl":"/shou-yin-ji/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"收银机编辑保存","methodUrl":"/shou-yin-ji/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"收银机删除","methodUrl":"/shou-yin-ji/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"收银机批量删除","methodUrl":"/shou-yin-ji/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
