<template>
    <el-dialog title="自助收银轮播图" :visible.sync="lunBoVisible" @close="cancel"
        destroy-on-close center append-to-body width="1000px">
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column label="门头照片" width="150" >
                <template slot-scope="scope">
                    <x-image class="image-style" :attachmentId="scope.row.id" :native="native"/>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <x-switch v-model="scope.row.use" size="small" dictType="T_USE" @change="zhuangTaiChange($event,scope.row.id)"/>
                </template>
            </el-table-column>
            <el-table-column label="排序" width="150" prop="sort"/>
            <el-table-column width="200px" align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" round @click="handleUpdate(scope.row)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
    </el-dialog>
</template>

<script>
    import {deleteLunBo,getLunBoList,lunBoUse} from "@/service/sbgl/ShouYinJi";
    import XTableBase from "@/components/x/XTableBase";
    import XImage from "@/components/x/upload/XImage";
    import Edit from "@/view/sbgl/syd/LunBoTuEdit"


    export default {
        name:"ShouYinJiLunBoEdit",
        mixins: [XTableBase],
        components:{XImage,Edit},
        data(){
            this.refreshService = getLunBoList
            return{
                shouYinObj:{},
                search:{},
                lunBoVisible:false,
                native:true,
            }
        },
        methods:{
            cancel(){
                this.lunBoVisible = false
            },
            handleDelete(id) {
                deleteLunBo(id).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },
            handleUpdate(row) {
                this.editor.isUpdate = true;
                this.editor.id = row.id;
                this.editor.visible = true;
                this.$refs.edit.fuJianObj = row
            },
            zhuangTaiChange(val,id){
                lunBoUse({id:id,use:val}).then((res)=>{
                    this.$message.success(res.msg);
                })
            }
        }
    }
</script>

<style scoped>
    .image-style{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;

    }
</style>
