<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" @close="cancel"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="700px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="排序" prop="sort" class="line">
                <el-input v-model="form.sort" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="背景图" prop="fuJians" class="line">
                <x-uploader v-model="form.fuJians" :list-type="listType" accept="image/jpeg,image/png" :size="1"></x-uploader>
            </el-form-item>
            <div>建议尺寸：60 x 60，或等比例图片</div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {update, getUpdate} from "@/service/sbgl/ShouYinJi";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_USE} from '@/util/constant'
    import XUploader from "@/components/x/upload/XUploader";
    import {deepCopy, deepMerge} from "@/util/objects"

    export default {
        mixins: [XEditBase],
        components:{XUploader},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                fuJians: [required('array',['blur', 'change'])], // 打印机编号
                sort: [required('string', ['blur', 'change'])], // 打印机名称
            }
            this.titlePrefix = '打印机';
            this.defaultForm = {
                id: null,
                ownerId:'',
                type:"",
                name:"",
                sort:'',
                use:DICT_USE.ENABLE.key,
                extension:"",
                path:'',
                size:'',
                fuJians:[]
            };
            this.updateService = update
        },
        data (){
            return {
                useDictTrue:DICT_USE.ENABLE.key,
                fuJians:[],
                fuJianObj:{},
                updateObj:{},
                listType:'picture-card'
            }
        },
        methods:{
            cancel(){
                this.fuJians = []
                this.fuJianObj = {}
                this.updateObj = {}
                this.form = deepCopy(this.defaultForm)
                this.editor.visible = false
            },
            open(){
                getUpdate().then((res)=>{
                    this.updateObj = res.data
                    if(this.isUpdate){
                        let findObj = this.updateObj.lunBoImg.find(item=>{return item.id===this.editor.id})
                        this.fuJians.push(findObj)
                        this.form = deepMerge(this.defaultForm, this.fuJianObj);
                        this.form.fuJians = this.fuJians
                        //清空校验
                        this._clearValidate();
                    }else{
                        this.form = deepCopy(this.defaultForm);
                        //清空校验
                        this._clearValidate();
                    }
                })
            },
            async save(callback){
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let fujian = this.form.fuJians[0]
                fujian.sort = this.form.sort
                fujian.use = this.form.use
                if(this.fuJianObj && this.fuJianObj.id){
                    fujian.id = this.fuJianObj.id
                }
                //处理最终需要更新的数据
                if(this.updateObj.lunBoImg && this.updateObj.lunBoImg.length>0){
                    if(fujian.id){
                        this.updateObj.lunBoImg.forEach(a=>{
                            if(a.id===fujian.id){
                                a=fujian
                            }
                        })
                    }else{
                        this.updateObj.lunBoImg.push(fujian)
                    }
                }else{
                    this.updateObj.lunBoImg = []
                    this.updateObj.lunBoImg.push(fujian)
                }
                let api =this.updateService;
                const response = await api(this.updateObj).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            }
        }
    }
</script>

<style scoped>
    .span-class {
        color: #009688;
        font-weight: bold;
        font-size: 12px;
        margin-left: 10px;
    }
</style>
